var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"searchable-table"},[_vm._t("search",function(){return [(_vm.searchEnabled)?_c('SearchableTableQueryInput',{class:_vm.searchClass,attrs:{"createCommand":_vm.createCommand},on:{"create":_vm.onButtonCreateClick},scopedSlots:_vm._u([(_vm.$scopedSlots.statistics)?{key:"statistics",fn:function(){return [_vm._t("statistics",null,{"itemsInPage":_vm.itemList.length})]},proxy:true}:null,{key:"customFilters",fn:function(){return [_vm._t("customFilters")]},proxy:true},{key:"extraButtons",fn:function(){return [_vm._t("extraButtons")]},proxy:true}],null,true),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}):_vm._e()]}),_vm._t("paginationBefore"),(_vm.pagination)?_c('TablePaginationNav',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPagination),expression:"showPagination"}],style:({'margin-top': _vm.searchEnabled ? '30px' : '6px'}),attrs:{"items":_vm.sortedList,"pageSize":_vm.pageSize,"nItems":_vm.nItemsForPagination,"multiColumnOrder":_vm.multiColumnOrder,"pageJump":_vm.pageJump,"serverPagination":_vm.pagination == 'server'},on:{"loadNextPage":function($event){return _vm.$emit('loadNextPage')},"pageChanged":function($event){return _vm.$emit('pageChanged', $event)},"showSortForm":_vm.onShowSortForm},model:{value:(_vm.pageItems),callback:function ($$v) {_vm.pageItems=$$v},expression:"pageItems"}}):_vm._e(),_vm._t("paginationAfter"),_c('div',{style:({
      'margin-top': _vm.showPagination || !_vm.searchEnabled ? '2px' : '30px',
      position: 'relative',
      clear: 'both'
    })},[_c('table',{ref:"table",class:_vm.tableClass,attrs:{"id":_vm.tableId ? _vm.tableId : 'tb_' + new Date().getTime()}},[_c('thead',[_c('tr',{staticClass:"sortable"},[(_vm.isMultiSelection)?_c('th',{staticClass:"text-primary text-center clicable",staticStyle:{"max-width":"20px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSelection.apply(null, arguments)}}},[_c('i',{class:_vm.checkAllClass})]):_vm._e(),(_vm.draggable)?_c('th',{staticClass:"draggable-th text-primary"},[_vm._v("#")]):_vm._e(),_vm._l((_vm.columns),function(field,ix){return [(_vm.isVisible(field))?_c('tho',{key:ix,style:(_vm.style(null, field)),attrs:{"field":field,"name":field.name,"title":field.title,"nowrap":true,"tooltip":field.tooltip || ''},on:{"sort":_vm.onSort}}):_vm._e()]}),(_vm.itemCommands.length)?_c('th',{staticClass:"text-center"},[(_vm.bulkCommands.length)?_c('div',{staticClass:"btn-group bulk-command"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("options"))+" ")]),_vm._m(0),_c('ul',{staticClass:"dropdown-menu"},_vm._l((_vm.bulkCommands),function(command){return _c('li',{key:command.name},[_c('a',{attrs:{"href":"#","data-testid":command.name + '-all'},on:{"click":function($event){$event.preventDefault();return _vm.onBulkCommand(command, $event.target)}}},[_vm._v(_vm._s(`${_vm.$tc("all")}: ${_vm.$tc( command.bulk.desc || (typeof command.bulk == "string" ? command.bulk : command.title) )}`))])])}),0)]):_c('span',[_vm._v(_vm._s(_vm.$t("options")))])]):_vm._e()],2)]),(_vm.draggable)?_c('draggable',_vm._g(_vm._b({staticClass:"table-body",attrs:{"draggable":".clicable-row","handle":".drag-handle","tag":"tbody"},on:{"update":function($event){return _vm.$emit('reorder', _vm.sortedList)}},model:{value:(_vm.sortedList),callback:function ($$v) {_vm.sortedList=$$v},expression:"sortedList"}},'draggable',_vm.draggable.options,false),_vm.draggable.listeners),[_vm._l((_vm.itemList),function(item,ix){return [_c('tr',{key:ix,class:[
              _vm.$attrs.disabled ? 'text-info' : 'text-info clicable-row',
              {
                active: _vm.showSelected && _vm.curPos > -1 && ix == _vm.curPos
              },
              _vm.getClass(item)
            ],attrs:{"data-testid":"item"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.select(ix, item)}}},[(_vm.isMultiSelection)?_c('td',{staticClass:"text-primary"},[_c('i',{staticClass:"fa fa-square-o"})]):_vm._e(),(_vm.draggable)?_c('td',{staticClass:"drag-handle"},[_c('span',{staticClass:"glyphicon glyphicon-move",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._l((_vm.fields),function(field,ix2){return _c('td',{key:ix2,style:(_vm.style(item, field)),attrs:{"data-testid":field.name}},[_vm._t(field.name,function(){return [_c('span',{staticClass:"text-default"},[_vm._v(_vm._s(_vm.value(item, field)))])]},{"item":item})],2)}),(_vm.itemCommands.length)?[_c('td',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
                    return false;
                  }).apply(null, arguments)}}},[_c('div',{staticClass:"action-cell"},[_vm._l((_vm.itemCommands),function(command,ix3){return [(command.name == '-')?_c('div',{key:ix3,staticClass:"btn-sep"}):_c('button',{key:ix3,staticClass:"btn btn-xs btn-default btn-action",attrs:{"tabindex":"-1","data-testid":command.name,"title":_vm.commandTitle(command, item),"disabled":!_vm.isCommandEnabled(command, item)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onButtonCommandClick(ix, command, item, $event.target)}}},[_c('Icon',{attrs:{"name":_vm.commandIcon(command, item)}})],1)]})],2)])]:_vm._e()],2)]})],2):_c('tbody',{staticClass:"table-body"},[(_vm.tree)?[_vm._l((_vm.treeItems),function(node){return [(!node.parent || _vm.isOpen(node.parent))?[_c('tr',{key:node.name,staticClass:"group-row no-select",style:(_vm.fieldAggregation(node).style)},[_vm._l((_vm.fields),function(field,ic){return [(field.visible)?_c('td',{key:field.name,style:(field.aggregation.style)},[_c('div',[(ic == 0)?_c('span',{staticClass:"clicable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleTreeNode(node.name)}}},[_c('i',{staticClass:"group-collapse-icon",class:_vm.nodeIcon(node).class,style:({
                            'padding-left': `${node.level * 5}px`,
                            color: _vm.nodeIcon(node).color
                          })}),_c('span',{staticStyle:{"padding-left":"10px"}},[_vm._t(`group-cell-${field.name}`,function(){return [_vm._v(" "+_vm._s(node.name.split(".")[node.level])+" ")]},{"nodeName":node.name})],2)]):_c('span',[_vm._t(`group-cell-${field.name}`,null,{"nodeName":node.name})],2)])]):_vm._e()]})],2),(_vm.isOpen(node) && node.leaf)?[_vm._l((_vm._sortList(node.leaf)),function(item,ix){return [_c('tr',{key:node.name + ix,staticClass:"clicable",class:{
                      active: _vm.showSelected && _vm.curPos > -1 && ix == _vm.curPos
                    },attrs:{"data-testid":"item"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.select(ix, item)}}},[_vm._l((_vm.fields),function(field,ix2){return [(_vm.isVisible(field, item))?_c('td',{key:ix2,style:(_vm.style(item, field)),attrs:{"data-testid":field.name}},[_vm._t(field.name,function(){return [_c('span',{staticClass:"text-default"},[_vm._v(_vm._s(_vm.value(item, field)))])]},{"item":item})],2):_vm._e()]}),(_vm.itemCommands.length)?[_c('td',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
                            return false;
                          }).apply(null, arguments)}}},[_c('div',{staticClass:"action-cell"},[_vm._l((_vm.itemCommands),function(command,ix3){return [(command.name == '-')?_c('div',{key:ix3,staticClass:"btn-sep"}):_c('button',{key:ix3,staticClass:"btn btn-xs btn-default btn-action",attrs:{"tabindex":"-1","data-testid":command.name,"title":_vm.commandTitle(command, item),"disabled":!_vm.isCommandEnabled(command, item)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onButtonCommandClick(
                                  ix,
                                  command,
                                  item,
                                  $event.target
                                )}}},[_c('Icon',{attrs:{"name":_vm.commandIcon(command, item)}})],1)]})],2)])]:_vm._e()],2)]})]:_vm._e()]:_vm._e()]})]:_vm._l((_vm.itemList),function(item,ix){return [_c('tr',{key:ix,class:[
              _vm.$attrs.disabled ? 'text-info' : 'text-info clicable-row',
              item.class ||
                (_vm.showSelected && _vm.curPos > -1 && ix == _vm.curPos ? 'active' : '')
            ],attrs:{"data-testid":"item"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.select(ix, item)}}},[(_vm.isMultiSelection)?_c('td',{class:_vm.isSelectionEnabled(item)
                  ? 'text-primary text-center'
                  : 'text-center disabled',staticStyle:{"max-width":"20px"},attrs:{"title":_vm.selectionHint(item)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSelection(ix, item)}}},[_c('i',{class:_vm.isSelected(item) ? 'fa fa-check-square-o' : 'fa fa-square-o'})]):_vm._e(),_vm._l((_vm.fields),function(field,ix2){return [(_vm.isVisible(field, item))?_c('td',{key:ix2,style:(_vm.style(item, field)),attrs:{"data-testid":field.name}},[_vm._t(field.name,function(){return [_c('span',{staticClass:"text-default",attrs:{"title":_vm.hint(item, field)},domProps:{"innerHTML":_vm._s(_vm.value(item, field))}})]},{"item":item})],2):_vm._e()]}),(_vm.itemCommands.length)?[_c('td',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
                    return false;
                  }).apply(null, arguments)}}},[_c('div',{staticClass:"action-cell"},[_vm._l((_vm.itemCommands),function(command,ix3){return [(command.name == '-')?_c('div',{key:ix3,staticClass:"btn-sep"}):_c('button',{key:ix3,staticClass:"btn btn-xs btn-default btn-action",attrs:{"tabindex":"-1","data-testid":command.name,"title":_vm.commandTitle(command, item),"disabled":!_vm.isCommandEnabled(command, item)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onButtonCommandClick(ix, command, item, $event.target)}}},[_c('Icon',{attrs:{"name":_vm.commandIcon(command, item)}})],1)]})],2)])]:_vm._e()],2),_vm._t(`after_row`,null,{"item":item})]})],2)],1)]),(_vm.sortForm)?_c('ColumnOrderSelection',{attrs:{"fields":_vm.fields},on:{"hide":function($event){_vm.sortForm = false},"sort":_vm.onMultiColumnSort}}):_vm._e(),(_vm.loading)?_c('Spin'):_vm._e(),_vm._t("empty")],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-link dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{staticClass:"caret"})])
}]

export { render, staticRenderFns }